@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./assets/fonts/fa-regular-400.eot");
  src: url("./assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/fa-regular-400.woff2") format("woff2"), url("./assets/fonts/fa-regular-400.woff") format("woff"), url("./assets/fonts/fa-regular-400.ttf") format("truetype"), url("./assets/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

*,
body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: radial-gradient(circle at top center, rgb(235, 41, 254),rgb(185, 42, 217),rgb(134, 42, 180),rgb(84, 43, 142),rgb(33, 43, 105));
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@import "./grid-layout";

#logo {
  width: 400px;
  height: auto;
}

#logo img {
  width: 400px;
  height: auto;
}

h2 {
  color: #787a7d;
  font-size: 36px;
  font-weight: 100;
}

.audio-wrap {
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.075);
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.52);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  max-width: 600px;
  width: 100%;
}
.audio-wrap.playing .btn {
  color: #ff6600;
}

.controls {
  padding: 0 12px 0 0;
}

.btn {
  align-items: center;
  background: #ab2bce;
  /* background-image: linear-gradient(to top, #222, #333); */
  border: 1px solid transparent;
  border-radius: 4px 0 0 4px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: center;
  font-size: 18px;
  transition: color 0.25s ease-in-out, border 0.25s ease-in-out;
  white-space: nowrap;
  width: 48px;
}

.btn:hover {
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.audio-spectrum-wrap {
  align-items: center;
  display: block;
  flex-grow: 1;
  justify-content: center;
}

#waveform1 {
  // background: rgba(0,0,0,0.8);
}
