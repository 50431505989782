#content {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 16px;
  grid-template-areas:
    "header header header"
    "sidebar main main"
    "footer footer footer";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
  min-height: 100vh;
  // max-width: 960px;
  width: 100vw;

  > * {
    background: rgba(255, 255, 255, .2);
    border-radius: 4px;
    padding: 30px;
  }
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  width: calc(100vw - 360px);
}

section {
  grid-area: sidebar;
  max-width: 360px;
  width: 100%;
}

footer {
  grid-area: footer;
}
